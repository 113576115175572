import {Div, PaperBlock, RootButton} from '@eon.cz/apollo13-frontend-common';
import {Link, Typography} from '@mui/material';
import Router from 'next/router';
import {FormattedMessage, useIntl} from 'react-intl';
import {Lang} from '../../../Lang';
import {LandingBackground} from '../../Common';

type Props = {
    statusCode: number;
};

/**
 * Komponenta zobrazí obsah s popisem chyby podle HTTP kódu, který obdrží.
 * Pro případ, kdy by uživatel přešel přímo na routu /_error, je to bráno jako chyba 404 (not found).
 * Routa /_error je dostupná přímo i v dev režimu.
 */
export const ErrorContainer = ({statusCode}: Props) => {
    const intl = useIntl();

    /**
     * Přesměruje uživatele na hlavní stránku (root)
     */
    const handleRootRedirect = () => Router.push('/');

    /**
     * Vrátí nadpis chyby podle http status kódu.
     *
     * @param {number} code - http status code
     */
    const getTitleByStatusCode = (code: number) => {
        switch (code) {
            case 200:
            case 404:
                return <FormattedMessage id={Lang.ERROR_HTTP_NOT_FOUND_TITLE} />;
            case 500:
                return <FormattedMessage id={Lang.ERROR_HTTP_INTERNAL_SERVER_ERROR_TITLE} />;
            default:
                return <FormattedMessage id={Lang.ERROR_HTTP_UNEXPECTED_TITLE} />;
        }
    };

    /**
     * Vrátí informační obsah chyby pro uživatele podle http status kódu.
     *
     * @param {number} code
     */
    const getContentByStatusCode = (code: number) => {
        switch (code) {
            // handling pro případ, kdy by uživatel přešel přímo na routu /_error, tak se mu vrátí 200
            case 200:
            // not found
            case 404:
                return (
                    <>
                        <Typography>
                            <FormattedMessage id={Lang.ERROR_HTTP_NOT_FOUND_CONTENT} />
                        </Typography>
                        <Typography>
                            <FormattedMessage id={Lang.ERROR_HTTP_HINT} />
                        </Typography>
                    </>
                );
            // internal server error
            case 500:
                return (
                    <>
                        <Typography>
                            <FormattedMessage id={Lang.ERROR_HTTP_INTERNAL_SERVER_ERROR_CONTENT1} />
                        </Typography>
                        <Typography>
                            <FormattedMessage id={Lang.ERROR_HTTP_HINT} />
                        </Typography>
                        <Typography>
                            <FormattedMessage
                                id={Lang.ERROR_HTTP_INTERNAL_SERVER_ERROR_CONTENT2}
                                values={{
                                    kontakt: (
                                        <Link sx={{color: 'primary.main'}} href={`mailto:${intl.formatMessage({id: 'support.email'})}`}>
                                            {intl.formatMessage({id: 'support.email'})}
                                        </Link>
                                    ),
                                }}
                            />
                        </Typography>
                    </>
                );
            // vše ostatní
            default:
                return (
                    <>
                        <Typography>
                            <FormattedMessage id={Lang.ERROR_HTTP_UNEXPECTED_CONTENT1} />
                        </Typography>
                        <Typography>
                            <FormattedMessage id={Lang.ERROR_HTTP_UNEXPECTED_CONTENT2} />
                        </Typography>
                        <Typography>
                            <FormattedMessage id={Lang.ERROR_HTTP_HINT} />
                        </Typography>
                    </>
                );
        }
    };

    return (
        <LandingBackground pageOff>
            <PaperBlock titleBlock={getTitleByStatusCode(statusCode)} elevation={8}>
                <Div sx={{padding: 2}}>{getContentByStatusCode(statusCode)}</Div>
                <Div sx={{display: 'flex', justifyContent: 'flex-end'}}>
                    <RootButton onClick={handleRootRedirect} />
                </Div>
            </PaperBlock>
        </LandingBackground>
    );
};
